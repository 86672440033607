import { useRef } from 'react';
import DatePicker from "react-datepicker";
import { Controller } from 'react-hook-form';

import { format } from 'date-fns';
import { Box, Flex, Icon, Text, Tooltip } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import "react-datepicker/dist/react-datepicker.css";

import { IField } from '../../interfaces/IField';
import { DefinedValidations } from '../../interfaces/Validations';
import { Asterisk, CalendarIcon } from '../../icons';
import useValidate from '../../hooks/useValidate';


interface IDatepicker extends IField {
  placeholder?: string;
  styles?: {
    font?: string
    fullWidth?: boolean
  };
  canSelectFutureDate?: boolean;
  inTable?: boolean;
}

const definedValidations: DefinedValidations = {
  notEmpty: (label, validationValue, value) => {
    if (validationValue && !value) {
      return `Cannot be empty`;
    }
  },
};

const Datepicker = ({ control, name, label = '', placeholder = '', tooltip = '', required, validations = {}, disabled = false,
  styles, canSelectFutureDate, inTable = false, variant }: IDatepicker) => {
  const datePickerRef = useRef();
  const validate = useValidate(label || name, validations || {}, definedValidations);
  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate }}
      render={({ field, fieldState, formState }) => {
        const { onChange, onBlur, value } = field;
        const { error } = fieldState;
        return (
          <Box w='full' id={name} minH={inTable ? "0px" : "80px"}>
            {
              label ? (
                <Flex pt={2} align='center' justify='space-between' mb='none'>
                  <Box
                    color={error ? "datepicker.labelFont.error" : styles ? styles?.font : "datepicker.labelFont.normal"}
                    fontWeight="bold"
                    fontSize='ssm'
                    position="static"
                    left='none'
                    zIndex={2}
                    mb='4.5px'
                    minH="16px"
                  >
                    {label}
                    {!disabled && validations?.notEmpty && <Asterisk h="8px" ml='5px' mb='8px' fill="datepicker.iconAsterisk" stroke='datepicker.iconAsterisk' />}
                    {tooltip && <Tooltip hasArrow label={tooltip} placement='top'><InfoOutlineIcon mb={1} h='14px' /></Tooltip>}
                  </Box>
                </Flex>
              ) :
                <Box pt='23px'></Box>
            }
            <Flex
              maxW={styles?.fullWidth ? 'full' : '360px'}
              minW={{ date: '130px', time: '110px', datetime: '190px' }[variant || 'datetime']}
              w='full'
              pl="3px"
              align='center'
              borderRadius={"8px"}
              borderWidth={"1px"}
              h="42px"
              mt={inTable ? '-12px' : label ? "2px" : "8px"}
              color={disabled ? "datepicker.disabled.font" : "datepicker.font"}
              bg={disabled ? "datepicker.disabled.bg" : "#FFF"}
              borderColor={disabled ? "datepicker.disabled.border" : error ? "datepicker.border.error" : "datepicker.border.normal"}
              cursor={disabled ? 'not-allowed' : 'pointer'}
              _active={{
                bg: disabled ? "datepicker.disabled.bg" : "datepicker.activeBg",
              }}
              _focus={{ borderColor: error ? "datepicker.border.focus.error" : "datepicker.border.focus.normal" }}
              justify="space-between"
              onTouchEnd={() => {
                // @ts-ignore
                !disabled && datePickerRef.current.setOpen(true);
              }}
            >
              {disabled
                ? <Text pl="17px" fontSize="smm" cursor="not-allowed" color={disabled ? "datepicker.disabled.font" : "datepicker.font"}>{value ? format(new Date(value), 'dd MMM yyyy') : ''}</Text>
                : <DatePicker
                  css={{ paddingTop: '0' }}
                  autoComplete="off"
                  dateFormat={variant === 'date' ? "dd MMM yyyy" : variant === 'time' ? "h:mm aa" : "dd MMM yyyy hh:mm aa"}
                  name={name}
                  onChange={date => {
                    if (variant === 'date') {
                      onChange(date ? format(date, 'M/d/yyyy') : '');
                      return;
                    }
                    onChange(date)
                  }}
                  onCalendarClose={onBlur}
                  selected={value ? new Date(value) : null}
                  placeholderText={placeholder}
                  ref={datePickerRef}
                  disabledKeyboardNavigation
                  showYearDropdown={true}
                  dropdownMode="select"
                  dateFormatCalendar="MMMM"
                  showTimeInput={variant === 'datetime'}
                  showTimeSelect={variant === 'time'}
                  showTimeSelectOnly={variant === 'time'}
                  timeFormat="hh:mm aa"
                  timeIntervals={5}
                  showTime={{ use12Hours: true }}
                  maxDate={canSelectFutureDate ? false : new Date()}
                  calendarStartDay={1}
                  popperProps={{
                    placement: "top",
                  }}
                />
              }
              <CalendarIcon
                w='15px'
                h='16px'
                mr='15px'
                opacity={disabled ? "0.7" : "1"}
                color={disabled ? 'datepicker.disabled.font' : 'datepicker.iconCalender'}
                onClick={() => {
                  // @ts-ignore
                  datePickerRef.current.setOpen(true);
                }}
                onTouchEnd={() => {
                  // @ts-ignore
                  datePickerRef.current.setOpen(true);
                }}
              />
            </Flex>
            {error && <Box fontSize="ssm" ml={1} color='datepicker.error'>{error.message}</Box>}
          </Box >
        );
      }}
    />
  );
};

export const datepickerStyles = {
  datepicker: {
    font: '#777777',
    bg: '#FFFFFF',
    labelFont: {
      normal: '#282F36',
      error: '#E53E3E',
    },
    iconAsterisk: '#DC0043',
    iconCalender: '#282F36',
    border: {
      normal: '#CBCCCD',
      error: '#E53E3E',
      focus: {
        normal: '#777777',
        error: '#E53E3E',
      },
    },
    activeBg: '#EEEEEE',
    disabled: {
      font: '#777777',
      border: '#EEEEEE',
      bg: '#f7f7f7',
    },
    error: '#E53E3E',
  },
};

export default Datepicker;
