import {
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  TableContainer,
  Button,
  Td,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

import { IField } from '../../../interfaces/IField';
import { DefinedValidations } from '../../../interfaces/Validations';
import useValidate from '../../../hooks/useValidate';
import TableHeader from '../TableHeader';
import { useEffect, useState, useContext } from 'react';
import { Asterisk, PlusIcon } from '../../../icons';
import { ModalContext } from '../../../contexts/ModalProvider';
import TableInputModal from './TableInputModal';
import TableInputRow from './TableInputRow';

interface ITableInput extends IField {
  data?: any;
  description?: string;
  variant?: string;
  help?: string;
  styles?: {
    font?: string
  };
}

const definedValidations: DefinedValidations = {
  notEmpty: (label, validationValue, value) => {
    if (validationValue && !value) {
      return `Cannot be empty`;
    }
  },
};

const TableInput = ({ control, name, label, description, data, validations = {}, disabled = false, help = '', required, styles, columns }: ITableInput) => {
  const validate = useValidate(label || name, validations || {}, definedValidations);
  const [dataValues, setdataValues] = useState(JSON.parse(JSON.stringify(data || [])));
  const { modalState, setModalState, setModalScope } = useContext(ModalContext);
  const [currentDataValue, setCurrentDataValue] = useState<any>("");

  useEffect(() => {
    setdataValues(control._formValues[name]);
  }, [control._formValues, name]);

  // Reset the form after closing
  useEffect(() => {
    if (modalState === "closed") {
      setCurrentDataValue("");
    }
  }, [modalState]);

  // If modal opened in edit or delete mode, reset the form and set values of edited element
  const openModal = (
    action: "add" | "edit" | "delete",
    dataValue?: any
  ) => {
    if (action !== "add") {
      setCurrentDataValue(dataValue);
    } else {
      setCurrentDataValue({});
    }
    setModalState(action);
    setModalScope(name);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate }}
      render={({ field, fieldState }) => {
        const { onChange } = field;
        const { error } = fieldState;

        return (
          <>
            <TableInputModal
              dataValues={dataValues}
              setdataValues={setdataValues}
              selectedDataValue={currentDataValue}
              onChange={onChange}
              columns={columns}
              name={name}
            />
            <Box w='full' id={name} mt='none'>
              {label && (
                <Flex pt='15px' pb={1} align='center' justify='space-between' mb='none'>
                  <Box
                    color={error ? 'textInput.labelFont.error' : styles ? styles?.font : 'textInput.labelFont.normal'}
                    fontWeight='bold'
                    fontSize='ssm'
                    position='static'
                    left='none'
                    zIndex={2}
                  >
                    {label}
                    {!disabled && validations?.notEmpty && <Asterisk h="8px" ml='5px' mb='8px' fill='textInput.iconAsterisk' stroke='textInput.iconAsterisk' />}
                    <Text
                      pt='5px'
                      color='textInput.labelFont.normal'
                      fontWeight='semi_medium'
                      fontSize='smm'
                    >{description}</Text>
                  </Box>
                </Flex>
              )}
              <TableContainer
                border={'1px solid'}
                borderColor='#F0F0F0'
                borderRadius='6'
                css={{ overflow: "auto !important" }}
              >
                <Table fontSize='smm' variant='simple'>
                  <Thead>
                    <Tr>
                      {columns?.map(({ label }, index) =>
                        <TableHeader header={label} key={`col-${label}-${index}`} styles={{ paddingInline: "0.5rem" }} />
                      )}
                      <TableHeader header="" styles={{ width: '60px', paddingInline: '5px' }} />
                      <TableHeader header="" styles={{ width: '24px', paddingInline: '5px' }} />
                    </Tr>
                  </Thead>
                  <Tbody>
                    {(dataValues || []).map((dataValue, index) => (
                      <TableInputRow
                        key={`row-${index}`}
                        columns={columns}
                        dataValue={dataValue}
                        index={index}
                        openModal={openModal}
                        disabled={disabled}
                      />
                    ))}
                    {!disabled && (
                      <Tr>
                        <Td p={2}>
                          <Button
                            p={3}
                            h="32px"
                            fontSize="ssm"
                            color="white"
                            bg="brePink"
                            rounded="6px"
                            leftIcon={<PlusIcon />}
                            onClick={() => openModal("add")}
                          >Add row</Button>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
              {error && <Box fontSize='ssm' ml={1} color='textInput.error'>{error.message}</Box>}
            </Box>
          </>
        );
      }}
    />
  );
};

export default TableInput;

export const amslerChecklistTableStyles = {
  signatureTable: {

  }
};
