import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useProjectsContext } from '../contexts/ProjectsProvider';

const PROJECT_TEMPLATE_W_FORMS = gql`
  query ($projectTemplateInput: ProjectTemplateInput!) {
    projectTemplate(projectTemplateInput: $projectTemplateInput) {
      _id
      name
      category
      isUkasRelated
      groupId
      formTemplates {
        _id
        name
        icon
        allowMultipleIterations
        commonFormId
        pages {
          _id
          index
          name
          sections {
            _id
            index
            name
            description
            rows {
              _id
              index
              fields {
                _id
                name
                type
                label
                required
                validations
                variant
                placeholder
                tooltip
                canSelectFutureDate
                clearAfterEdit
                saveDateOfSignature
                useRichTextLabelInput
                options {
                  value
                  label
                }
                image
                rows {
                  value
                  label
                  metadata {
                    isBold
                  }
                }
                columns {
                  type
                  value
                  label
                  options {
                    value
                    label
                    metadata {
                      isBold
                    }
                  }
                  variant
                  required
                  validations
                }
              }
            }
          }
        }
      }
    }
  }
`;
const PROJECT_TEMPLATE = gql`
  query ($projectTemplateInput: ProjectTemplateInput!) {
    projectTemplate(projectTemplateInput: $projectTemplateInput) {
      _id
      name
      category
      isUkasRelated
      groupId
    }
  }
`;

const useGetProjectTemplate = (selector: { _id?: string; projectId?: string }, withForms: boolean = false) => {
  const { setCurrentProjectTemplate } = useProjectsContext();
  const {
    loading: projectTemplateLoading,
    data,
    error: projectTemplateError,
    refetch,
  } = useQuery(withForms ? PROJECT_TEMPLATE_W_FORMS : PROJECT_TEMPLATE, {
    variables: {
      projectTemplateInput: selector,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) setCurrentProjectTemplate(data.projectTemplate);
    return () => {
      setCurrentProjectTemplate(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { projectTemplateLoading, projectTemplateError, refetchProjectTemplate: refetch };
};

export default useGetProjectTemplate;
