import { IFormField } from '../../interfaces/IFormField';

export interface IField extends IFormField {
  formFields?: IFieldFormField[];
}

export interface IFieldFormField {
  _id?: string;
  fieldType: 'text' | 'richTextEditor' | 'switch' | 'switchToVariant' | 'select' | 'options' | 'columns' | 'image' | 'label';
  label: string;
  placeholder: string;
  required: boolean;
  control:
  | 'name'
  | 'label'
  | 'placeholder'
  | 'tooltip'
  | 'required'
  | 'variant'
  | 'canSelectFutureDate'
  | 'clearAfterEdit'
  | 'options'
  | 'image'
  | 'columns'
  | 'saveDateOfSignature';
  options?: { value: string; label: string }[];
  image?: { fileId: string; fileName: string };
  unique?: boolean;
}

const labelInput: IFieldFormField = {
  fieldType: 'label',
  control: 'label',
  label: 'Title',
  placeholder: 'Title',
  required: true,
};
const nameInput: IFieldFormField = {
  fieldType: 'text',
  control: 'name',
  label: 'Database name',
  placeholder: 'Name',
  required: true,
  unique: true,
};
const placeholderInput: IFieldFormField = {
  fieldType: 'text',
  label: 'Placeholder text',
  control: 'placeholder',
  placeholder: 'Placeholder',
  required: false,
};
const helperTextInput: IFieldFormField = {
  fieldType: 'text',
  label: 'Help text',
  placeholder: 'Help text',
  control: 'tooltip',
  required: false,
};
const isMandatoryInput: IFieldFormField = {
  fieldType: 'switch',
  label: 'Mandatory field',
  placeholder: '',
  control: 'required',
  required: false,
};
const isNumericField: IFieldFormField = {
  fieldType: 'switchToVariant',
  label: 'Numeric field',
  placeholder: 'numeric',
  control: 'variant',
  required: false,
};

export const fields: ReadonlyArray<IField> = [
  {
    name: 'Text input',
    type: 'textInput',
    formFields: [
      labelInput,
      nameInput,
      placeholderInput,
      helperTextInput,
      isMandatoryInput,
      isNumericField,
    ],
  },
  {
    name: 'Multi-line text',
    type: 'textInput',
    variant: 'multiline',
    formFields: [
      labelInput,
      nameInput,
      placeholderInput,
      helperTextInput,
      isMandatoryInput,
    ],
  },
  {
    name: 'Checkbox',
    type: 'checkbox',
    formFields: [
      labelInput,
      nameInput,
      helperTextInput,
      isMandatoryInput,
      {
        fieldType: 'options',
        label: 'Dropdown Options',
        control: 'options',
        placeholder: '',
        required: false,
      },
    ],
  },
  {
    name: 'Radio',
    type: 'radio',
    formFields: [
      labelInput,
      nameInput,
      helperTextInput,
      isMandatoryInput,
      {
        fieldType: 'options',
        label: 'Options',
        control: 'options',
        placeholder: '',
        required: false,
      },
    ],
  },
  {
    name: 'Date Picker',
    type: 'datepicker',
    formFields: [
      {
        fieldType: 'select',
        label: 'Type',
        placeholder: 'Select',
        control: 'variant',
        options: [
          { value: 'date', label: 'Date only' },
          { value: 'datetime', label: 'Date and time' },
          { value: 'time', label: 'Time only' },
        ],
        required: true,
      },
      labelInput,
      nameInput,
      placeholderInput,
      helperTextInput,
      isMandatoryInput,
      {
        fieldType: 'switch',
        label: 'Can select future date?',
        placeholder: '',
        control: 'canSelectFutureDate',
        required: false,
      },
    ],
  },
  {
    name: 'Dropdown',
    type: 'dropdown',
    formFields: [
      labelInput,
      nameInput,
      helperTextInput,
      isMandatoryInput,
      {
        fieldType: 'options',
        label: 'Dropdown Options',
        control: 'options',
        placeholder: '',
        required: false,
      },
    ],
  },
  {
    name: 'Yes/No Question',
    type: 'toggle',
    formFields: [
      labelInput,
      nameInput,
      isMandatoryInput,
    ],
  },
  {
    name: 'People picker',
    type: 'peoplePicker',
    formFields: [
      labelInput,
      nameInput,
      placeholderInput,
      helperTextInput,
      isMandatoryInput,
      {
        fieldType: 'select',
        label: 'AAD group',
        placeholder: 'Select',
        control: 'variant',
        options: [
          { value: 'widerTeam', label: 'Wider team' },
          { value: 'projectLeads', label: 'Project leads' },
        ],
        required: true,
      },
    ],
  },
  {
    name: 'Image uploader',
    type: 'imageCapture',
    formFields: [
      labelInput,
      nameInput,
      helperTextInput,
      isMandatoryInput
    ],
  },
  {
    name: 'Drawing',
    type: 'freeHandInput',
    formFields: [
      labelInput,
      nameInput,
      helperTextInput,
      isMandatoryInput
    ],
  },
  {
    name: 'Signature input',
    type: 'signatureApproval',
    formFields: [
      labelInput,
      nameInput,
      helperTextInput,
      isMandatoryInput,
      {
        fieldType: 'switch',
        label: 'Clear signature when changing form state from completed back to edit',
        placeholder: '',
        control: 'clearAfterEdit',
        required: false,
      },
      {
        fieldType: 'switch',
        label: 'Save date of signature',
        placeholder: '',
        control: 'saveDateOfSignature',
        required: false,
      }
    ],
  },
  {
    name: 'Information note',
    type: 'staticText',
    formFields: [
      {
        fieldType: 'richTextEditor',
        control: 'label',
        label: 'Note',
        placeholder: 'Information note',
        required: true,
      }
    ],
  },
  {
    name: 'Information image',
    type: 'staticImage',
    formFields: [
      labelInput,
      helperTextInput,
      {
        fieldType: 'image',
        label: 'Upload Image',
        control: 'image',
        required: true,
        placeholder: '',
      },
    ],
  },
  {
    name: 'Static table',
    type: 'staticTableInput',
    formFields: [
      labelInput,
      nameInput,
      helperTextInput,
      {
        fieldType: 'columns',
        label: 'Columns',
        control: 'columns',
        placeholder: '',
        required: false,
      },
    ],
  },
  {
    name: 'Table',
    type: 'tableInput',
    formFields: [
      labelInput,
      nameInput,
      helperTextInput,
      {
        fieldType: 'columns',
        label: 'Columns',
        control: 'columns',
        placeholder: '',
        required: false,
      },
    ],
  },
  {
    name: 'Project number',
    type: 'projectData',
    disabled: true,
    data: 'reference',
  },
  {
    name: 'Client name',
    type: 'projectData',
    disabled: true,
    data: 'clientName',
  },
];
