import {
  AddIcon,
  CalendarIcon,
  CheckIcon,
  DeleteIcon,
  EditIcon,
  SearchIcon,
} from '@chakra-ui/icons';
import AmslerChecklistIcon from './AmslerChecklistIcon';
import ArrowRight from './ArrowRight';
import Asterisk from './Asterisk';
import AuditLogIcon from './AuditLogIcon';
import BookIcon from './BookIcon';
import BuildIcon from './BuildIcon';
import ChevronDown from './ChevronDown';
import ClientContractorsIcon from './ClientContractorsIcon';
import ConformeSmall from './ConformeSmall';
import CrossIcon from './CrossIcon';
import DotsHorizontal from './DotsHorizontal';
import DrawingIcon from './DrawingIcon';
import FurnaceClosureIcon from './FurnaceClosureIcon';
import GlossaryIcon from './GlossaryIcon';
import InstrumentationIcon from './InstrumentationIcon';
import LabSetupIcon from './LabSetupIcon';
import PeopleIcon from './PeopleIcon';
import PeerReviewIcon from './PeerReviewIcon';
import PersonIcon from './PersonIcon';
import PlusIcon from './PlusIcon';
import PhotoIcon from './PhotoIcon';
import PostTestIcon from './PostTestIcon';
import ProgressIcon from './ProgressIcon';
import ProjectSummaryIcon from './ProjectSummaryIcon';
import SaveIcon from './SaveIcon';
import ShareIcon from './ShareIcon';
import TestObservationsIcon from './TestObservationsIcon';
import BriefcaseIcon from './BriefcaseIcon';
import BullhornIcon from './BullhornIcon';
import CalculatorIcon from './CalculatorIcon';
import DoubleSquareIcon from './DoubleSquareIcon';
import ClipboardIcon from './ClipboardIcon';
import FileLines from './FileLines';
import FolderIcon from './FolderIcon';
import GlobeIcon from './GlobeIcon';
import ComputerIcon from './ComputerIcon';
import LinesChecked from './LinesChecked';
import PencilIcon from './PencilIcon';
import PencilBoxIcon from './PencilBoxIcon';
import TvIcon from './TvIcon';
import HeadphoneIcon from './HeadphoneIcon';
import MagnetIcon from './MagnetIcon';
import AlignmentIcon from './AlignmentIcon';
import WideArrowIcon from './WideArrowIcon';
import CompassIcon from './CompassIcon';
import TrafficConeIcon from './TrafficConeIcon';
import WarningIcon from './WarningIcon';
import RollerIcon from './RollerIcon';
import WavesIcon from './WavesIcon';
import ScaleIcon from './ScaleIcon';
import PointsLineIcon from './PointsLineIcon';
import TrainIcon from './TrainIcon';
import CircleCheckIcon from './CircleCheckIcon';
import CogIcon from './CogIcon';
import TightenNutIcon from './TightenNutIcon';
import RewindIcon from './RewindIcon';
import StarIcon from './StarIcon';
import FloppyDriveIcon from './FloppyDriveIcon';
import MovieReelIcon from './MovieReelIcon';
import CloudIcon from './CloudIcon';
import CommentIcon from './CommentIcon';
import ShieldIcon from './ShieldIcon';
import ClockFastIcon from './ClockFastIcon';
import InfinityIcon from './InfinityIcon';
import DotsVertical from './DotsVertical';
import PreviewIcon from './PreviewIcon';
import SignatureIcon from './SignatureIcon';
import ScaleBalanced from './ScaleBalanced';
import HammerIcon from './HammerIcon';
import LaptopFileIcon from './LaptopFileIcon';
import ListCheck from './ListCheck';
import PersonChalkboard from './PersonChalkboard';
import SquarePollVerticalIcon from './SquarePollVerticalIcon';
import TagsIcon from './TagsIcon';
import HelmetIcon from './HelmetIcon';
import PersonDiggingIcon from './PersonDiggingIcon';
import ScrewDriverWrenchIcon from './ScrewDriverWrenchIcon';
import TruckPickupIcon from './TruckPickupIcon';
import RotateIcon from './RotateIcon';
import WandMagicSparklesIcon from './WandMagicSparklesIcon';
import TrashIcon from './TrashIcon';
import DuplicatePageIcon from './DuplicatePageIcon';
import ReplaceIcon from './ReplaceIcon';
import PreTestIcon from './PreTesticon';

// This object is used to lookup the left navigation icons on the project page, using the project-templates icon values
const getIcon = {
  AmslerChecklistIcon,
  AuditLogIcon,
  BuildIcon,
  ClientContractorsIcon,
  FurnaceClosureIcon,
  HammerIcon,
  InstrumentationIcon,
  LabSetupIcon,
  LaptopFileIcon,
  ListCheck,
  PersonChalkboard,
  SquarePollVerticalIcon,
  TagsIcon,
  HelmetIcon,
  PersonDiggingIcon,
  ScrewDriverWrenchIcon,
  TruckPickupIcon,
  RotateIcon,
  WandMagicSparklesIcon,
  PeerReviewIcon,
  PostTestIcon,
  ProjectSummaryIcon,
  TestObservationsIcon,
  AlignmentIcon,
  BookIcon,
  BriefcaseIcon,
  BullhornIcon,
  CalculatorIcon,
  CircleCheckIcon,
  ClipboardIcon,
  ClockFastIcon,
  CloudIcon,
  CogIcon,
  CommentIcon,
  CompassIcon,
  ComputerIcon,
  DoubleSquareIcon,
  DuplicatePageIcon,
  FileLines,
  FloppyDriveIcon,
  FolderIcon,
  GlobeIcon,
  HeadphoneIcon,
  InfinityIcon,
  LinesChecked,
  MagnetIcon,
  MovieReelIcon,
  PencilBoxIcon,
  PencilIcon,
  PointsLineIcon,
  RewindIcon,
  RollerIcon,
  SaveIcon,
  ScaleIcon,
  ShieldIcon,
  StarIcon,
  ScaleBalanced,
  TightenNutIcon,
  TrafficConeIcon,
  TrainIcon,
  TvIcon,
  WarningIcon,
  WavesIcon,
  WideArrowIcon,
  SignatureIcon,
  PreTestIcon,
};

export {
  getIcon,
  AddIcon,
  ArrowRight,
  AmslerChecklistIcon,
  Asterisk,
  AuditLogIcon,
  BookIcon,
  BriefcaseIcon,
  BullhornIcon,
  BuildIcon,
  CalculatorIcon,
  CalendarIcon,
  CheckIcon,
  ChevronDown,
  ConformeSmall,
  CrossIcon,
  ClientContractorsIcon,
  ClipboardIcon,
  FileLines,
  FolderIcon,
  GlobeIcon,
  ComputerIcon,
  LinesChecked,
  PencilIcon,
  PencilBoxIcon,
  TvIcon,
  HeadphoneIcon,
  MagnetIcon,
  AlignmentIcon,
  WideArrowIcon,
  CompassIcon,
  TrafficConeIcon,
  WarningIcon,
  RollerIcon,
  WavesIcon,
  ScaleIcon,
  PointsLineIcon,
  TrainIcon,
  CircleCheckIcon,
  CogIcon,
  TightenNutIcon,
  RewindIcon,
  StarIcon,
  FloppyDriveIcon,
  MovieReelIcon,
  CloudIcon,
  CommentIcon,
  ShieldIcon,
  ClockFastIcon,
  DeleteIcon,
  DotsHorizontal,
  DotsVertical,
  DrawingIcon,
  DoubleSquareIcon,
  EditIcon,
  HammerIcon,
  FurnaceClosureIcon,
  GlossaryIcon,
  InstrumentationIcon,
  InfinityIcon,
  LabSetupIcon,
  LaptopFileIcon,
  ListCheck,
  PersonChalkboard,
  SquarePollVerticalIcon,
  TagsIcon,
  HelmetIcon,
  PersonDiggingIcon,
  ScrewDriverWrenchIcon,
  TruckPickupIcon,
  RotateIcon,
  WandMagicSparklesIcon,
  PhotoIcon,
  PeerReviewIcon,
  PeopleIcon,
  PersonIcon,
  PreviewIcon,
  PlusIcon,
  PostTestIcon,
  ProgressIcon,
  ProjectSummaryIcon,
  ReplaceIcon,
  SaveIcon,
  SearchIcon,
  ShareIcon,
  SignatureIcon,
  ScaleBalanced,
  TestObservationsIcon,
  TrashIcon,
  DuplicatePageIcon,
  PreTestIcon,
};
