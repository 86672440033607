import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { uniqBy } from 'lodash';

import { useFiltersContext } from '../contexts/FiltersProvider';
import { useProjectsContext } from '../contexts/ProjectsProvider';
import { useAppContext } from '../contexts/AppProvider';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { toastFailed } from '../bootstrap/config';

const PROJECTS_W_FORMS = gql`
  query ($searchInput: SearchInput!, $pagination: Pagination) {
    searchProjects(searchInput: $searchInput, pagination: $pagination) {
      projects {
        _id
        clientId
        reference
        status
        clientName
        projectLeadId
        projectLead {
          _id
          imgUrl
          displayName
        }
        testers {
          _id
          imgUrl
          displayName
        }
        trainees {
          _id
          imgUrl
          displayName
        }
        groupId
        forms {
          _id
          name
          index
          icon
          allowMultipleIterations
          pages {
            _id
            index
            name
            sections {
              _id
              index
              name
              description
              rows {
                _id
                index
                fields {
                  _id
                  name
                  type
                  label
                  required
                  validations
                  variant
                  placeholder
                  tooltip
                  canSelectFutureDate
                  clearAfterEdit
                  saveDateOfSignature
                  useRichTextLabelInput
                  secondarylabel
                  requiredWhen
                  options {
                    value
                    label
                    metadata {
                      isBold
                    }
                  }
                  image
                  rows {
                    value
                    label
                  }
                  columns {
                    type
                    value
                    label
                    placeholder
                    options {
                      value
                      label
                      metadata {
                        isBold
                      }
                    }
                    variant
                    required
                    validations
                  }
                }
              }
            }
          }
          iterations {
            status
            values
          }
        }
      }
      total
    }
  }
`;
const PROJECTS = gql`
  query ($searchInput: SearchInput!, $pagination: Pagination) {
    searchProjects(searchInput: $searchInput, pagination: $pagination) {
      projects {
        _id
        clientId
        reference
        status
        clientName
        projectLeadId
        projectLead {
          _id
          imgUrl
          displayName
        }
        testers {
          _id
          imgUrl
          displayName
        }
        trainees {
          _id
          imgUrl
          displayName
        }
        projectTemplate {
          name
          category
        }
        groupId
      }
      total
    }
  }
`;

const useGetProjects = ({ sortBy, sortDirection, projectId, withForms }: {
  sortBy?: string,
  sortDirection?: string,
  projectId?: string,
  withForms?: boolean,
}) => {
  const history = useHistory();
  const toast = useToast();
  const { settings } = useAppContext();
  const { setProjects, setCurrentProject, setTotalProjects } = useProjectsContext();
  const { filters } = useFiltersContext();
  const { loading, data, error, refetch, fetchMore } = useQuery(withForms ? PROJECTS_W_FORMS : PROJECTS, {
    variables: {
      searchInput: projectId ? {
        _id: projectId,
      } : filters,
      pagination: {
        offset: 0,
        limit: settings?.projectsPerPage,
        sortBy,
        sortDirection,
      }
    },
    fetchPolicy: 'network-only',
    skip: !settings?.projectsPerPage,
  });

  useEffect(() => {
    // filters change, update the query
    if (data) {
      setProjects(data.searchProjects.projects);
      setTotalProjects(data.searchProjects.total);
    }
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (projectId) {
      if (data) {
        if (data.searchProjects.projects.length > 0) {
          setCurrentProject(data.searchProjects.projects[0]);
          setTotalProjects(data.searchProjects.total);
        } else {
          toast({ ...toastFailed, description: 'Project not found' });
          history.push('/');
        }
      }
    } else {
      // data changes, update projects
      if (data) {
        setProjects(data.searchProjects.projects);
        setTotalProjects(data.searchProjects.total);
      }
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadMoreProjects = async (page: number) => {
    if (settings?.projectsPerPage) {
      const res = await fetchMore({
        variables: {
          pagination: {
            offset: page * settings.projectsPerPage,
            limit: settings.projectsPerPage,
            sortBy,
            sortDirection,
          },
        },
      });
      if (res.data?.searchProjects?.projects?.length > 0)
        setProjects((projects) => uniqBy([...(projects || []), ...res.data.searchProjects.projects], '_id'));
    }
  };

  return { loading, data, error, refetch, loadMoreProjects };
};

export default useGetProjects;
