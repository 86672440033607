import { ReactElement } from "react";
import {
  Th
} from "@chakra-ui/react";
import { Asterisk } from "../../icons";

interface ITableData {
  header: string;
  styles?: any;
  icon?: ReactElement;
  onClick?: () => void;
  required?: boolean
}

const TableHeader = ({ header, styles, onClick, icon, required }: ITableData) => {
  return (
    <Th
      fontFamily="body"
      whiteSpace="nowrap"
      ml={10}
      fontWeight="semi_medium"
      textTransform="none"
      color="tableHeader.color"
      fontSize="ssm"
      paddingInline="0px"
      cursor="pointer"
      onClick={onClick}
      paddingInlineStart={styles.paddingInline || 'none'}
      paddingInlineEnd={styles.paddingInline || 'none'}
      {...styles}
    >
      {header}
      {icon}
      {required && <Asterisk h="7px" ml='3px' mb='8px' fill="datepicker.iconAsterisk" stroke='datepicker.iconAsterisk' />}
    </Th>
  );
};

export default TableHeader;

export const tableStyles = {
  tableHeader: {
    color: '#818197',
  }
}
