import { useEffect, useRef, useState } from 'react';
import { Box, Flex, Input, Tooltip, InputGroup, InputRightElement, Avatar, InputLeftElement } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { gql, useQuery } from "@apollo/client";
import { Controller } from 'react-hook-form';

import { IField } from '../../interfaces/IField';
import Loader from '../Loader';
import { IUser } from '../../interfaces/IUser';
import { ArrowRight, Asterisk, CrossIcon } from '../../icons';
import { DefinedValidations } from '../../interfaces/Validations';
import useValidate from '../../hooks/useValidate';

interface IPeoplePicker extends IField {
  placeholder?: string;
  variant?: string;
  help?: string;
  onChange?: (value: string) => void;
  inTable?: boolean;
  styles?: {
    fullWidth?: boolean
    width?: string
  }
}

const SEARCH_USERS = gql`
  query ($searchQuery: SearchQuery) {
    searchUsers(searchQuery: $searchQuery) {
      _id
      firstName
      lastName
      displayName
    }
  }
`;

const definedValidations: DefinedValidations = {
  notEmpty: (label, validationValue, value) => {
    if (validationValue && !value) {
      return `Cannot be empty`;
    }
  },
};

const PeoplePicker = ({ control, name, label, placeholder = '', tooltip = '', variant, validations = {}, disabled, help = '', onChange: onAlter, inTable = false, styles }: IPeoplePicker) => {
  const [showResults, setShowResults] = useState<Boolean>(false);
  const [searchText, setSearchText] = useState('');
  const [searchedInputValue, setSearchedInputValue] = useState('')
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [clearedInput, setClearedInput] = useState<boolean>(false);
  const [dropdownDirection, setDropdownDirection] = useState<'bottom' | 'top'>('bottom');
  const { data, loading, refetch } = useQuery(SEARCH_USERS, {
    variables: {
      searchQuery: {
        searchText,
        variant,
      }
    }
  });
  const validate = useValidate(label || name, validations || {}, definedValidations);
  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data) {
      setUsers(
        [...data.searchUsers]
      );
    } else {
      setUsers([]);
    }
  }, [data]);

  useEffect(() => {
    refetch()
  }, [refetch, searchText]);

  useEffect(() => {
    const adjustDropdownPosition = () => {
      if (inputRef.current) {
        const inputRect = inputRef.current.getBoundingClientRect();
        const containerRect = inputRef?.current?.closest('table')?.getBoundingClientRect();
        const windowHeight = window.innerHeight;
  
        const dropdownHeight = 150;
  
        // Calculate space without table (i.e., relative to window)
        const spaceBelowWithoutTable = windowHeight - inputRect.bottom;
        const spaceAboveWithoutTable = inputRect.top;
  
        // If we are inside a table, calculate relative space with respect to the table
        const spaceBelowWithTable = containerRect ? containerRect.bottom - inputRect.bottom : spaceBelowWithoutTable;
        const spaceAboveWithTable = containerRect ? inputRect.top - containerRect.top : spaceAboveWithoutTable;
  
        if (containerRect) {
          if (spaceBelowWithTable < dropdownHeight && spaceAboveWithTable > dropdownHeight) {
            setDropdownDirection('top');
          } else {
            setDropdownDirection('bottom');
          }
        }
        else {
          if (spaceBelowWithoutTable < dropdownHeight && spaceAboveWithoutTable > dropdownHeight) {
            setDropdownDirection('top');
          } else {
            setDropdownDirection('bottom');
          }
        }
      }
  
    };

    adjustDropdownPosition();

    window.addEventListener('resize', adjustDropdownPosition);
    return () => window.removeEventListener('resize', adjustDropdownPosition);
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate }}
      render={({ field, fieldState, formState }) => {
        const { onChange, value } = field;
        const { error } = fieldState;
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (value || searchedInputValue) {
            const user = users.find(user => user._id === value)
            if (user && clearedInput === false) {
              setSearchedInputValue(user.displayName)
            }
          } else {
            setSearchedInputValue('')
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value, users])
        return (
          <Box w='full' id={name} mt='3px' position="relative" minH={inTable ? "0px" : "80px"} ref={inputRef}>
            <Box>
              {label && (
                <Flex pt={2} pb={1} align='center' justify="space-between" mb='none'>
                  <Box
                    color={error ? "peoplePicker.labelFont.error" : "peoplePicker.labelFont.normal"}
                    fontWeight="bold"
                    fontSize={11}
                    position="static"
                    left='none'
                    zIndex={2}
                    minH="16px"
                  >
                    {label}
                    {!disabled && validations?.notEmpty && <Asterisk h="8px" ml='5px' mb='8px' fill='textInput.iconAsterisk' stroke='textInput.iconAsterisk' />}
                    {' '}
                    {tooltip && <Tooltip hasArrow label={tooltip} placement='top'><InfoOutlineIcon mb={1} h='14px' /></Tooltip>}
                  </Box>
                </Flex>
              )}
              <InputGroup maxW={styles?.fullWidth ? '1109px' : styles?.width ? styles?.width : '360px'}>
                <Input
                  _active={{ bg: disabled ? "peoplePicker.disabled.bg" : "peoplePicker.activeBg" }}
                  _focus={{ borderColor: error ? "peoplePicker.border.focus.error" : "peoplePicker.border.focus.normal" }}
                  fontSize="smm"
                  borderRadius="8px"
                  autoComplete="none"
                  borderWidth="1px"
                  color={disabled ? "peoplePicker.disabled.font" : "peoplePicker.font"}
                  bg="peoplePicker.bg"
                  borderColor={error ? "peoplePicker.border.error" : disabled ? 'peoplePicker.disabled.border' : "peoplePicker.border.normal"}
                  h="42px"
                  mb={0}
                  pl={searchedInputValue ? "55px" : "20px"}
                  zIndex={2}
                  value={searchedInputValue}
                  isDisabled={disabled}
                  _disabled={{ cursor: "not-allowed", bg: "#f7f7f7" }}
                  onChange={e => {
                    setTimeout(() => setSearchText(e.target.value), 1000)
                    setSearchedInputValue(e.target.value)
                    onChange({ target: { name, value: '' } })
                  }}
                  onBlur={() => setTimeout(() => setShowResults(false), 200)}
                  onFocus={() => setShowResults(true)}
                  placeholder={placeholder}
                  _placeholder={{}}
                />
                {searchedInputValue &&
                  <InputLeftElement
                    ml="10px"
                    mt="1px"
                    children={
                      <Avatar
                        bg="breNavy"
                        size='sm'
                        src={`${process.env.REACT_APP_API_URL}/files/photo/${value ?? selectedUser?._id}`}
                      />
                    }

                  />}
                {!disabled &&
                  <InputRightElement
                    w="32px"
                    mt="2px"
                    justifyContent="flex-end"
                    cursor="pointer"
                    children={
                      <Flex bg="#FFF" mr="1px" onClick={() => setShowResults(!showResults)}>
                        {searchedInputValue &&
                          <CrossIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              setClearedInput(true)
                              setSearchedInputValue('')
                              onChange({ target: { name, value: '' } });
                              setSearchText('');
                            }}
                            stroke="brePink"
                            transform="rotate(90deg)"
                          />
                        }
                        <ArrowRight mx={2} stroke="peoplePicker.icon" transform="rotate(90deg)" />
                      </Flex>
                    }
                  />
                }
              </InputGroup>
              {showResults && (
                <Flex
                  maxW={styles?.fullWidth ? '1109px' : styles?.width ? styles?.width : '360px'}
                  position='absolute'
                  bg="peoplePicker.bg"
                  direction='column'
                  boxShadow='lg'
                  rounded='lg'
                  zIndex={3}
                  maxH="150px"
                  overflowY="auto"
                  w="100%"
                  top={dropdownDirection === 'top' ? users.length > 0 ? '-150px' : '-42px' : '42px'}
                >
                  {loading ? (
                    <Box p={4}>
                      <Loader size='sm' />
                    </Box>
                  ) : (
                    users.length > 0 ? (
                      users.map(user => {
                        return (
                          <Flex
                            key={user._id}
                            onClick={() => {
                              setSelectedUser(user)
                              setSearchText('');
                              setSearchedInputValue(user.displayName)
                              onChange(() => {
                                if (onAlter) return onAlter(user._id)
                                onChange({ target: { name, value: user._id } })
                              });
                              setShowResults(false);
                            }}
                            pl={3}
                            w='full'
                            h='50px'
                            fontWeight='400'
                            rounded='md'
                            align='center'
                            justify='flex-start'
                            color='peoplePicker.font'
                            role='group'
                            _hover={{ cursor: 'pointer', bg: 'peoplePicker.hover.bg' }}
                          >
                            <Avatar bg="breNavy" src={`${process.env.REACT_APP_API_URL}/files/photo/${user._id}`} size='sm' mr={3} my={2}></Avatar>
                            {user.displayName}
                          </Flex>
                        )
                      })
                    ) : (
                      <Flex align='center' fontStyle='italic' pl={5} h='35px'>No results found</Flex>
                    )
                  )}
                </Flex>
              )}
              {error && <Box fontSize="smm" pl={3} mt={1} color='peoplePicker.error' >{error.message}</Box>}
            </Box>
          </Box>
        )
      }}
    />
  );
};

export default PeoplePicker;

export const peoplePickerStyles = {
  peoplePicker: {
    font: '#777777',
    bg: '#FFFFFF',
    labelFont: {
      normal: '#282F36',
      error: '#E53E3E',
    },
    hover: {
      bg: "#F2F2F2"
    },
    border: {
      normal: 'rgba(129, 129, 151, 0.4)',
      error: '#E53E3E',
      focus: {
        normal: '#777777',
        error: '#E53E3E',
      },
    },
    activeBg: '#EEEEEE',
    disabled: {
      font: '#777777',
      border: '#EEEEEE',
      bg: '#f7f7f7',
    },
    icon: '#282F36',
    placeholder: '#282F36',
    error: '#E53E3E',
    tooltip: "#9A9EA1"
  }
}
