import { Box, Button, Flex, FormLabel, HStack, Switch, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useForm, UseFormSetValue } from 'react-hook-form';
import pluralize from 'pluralize';
import { useAppContext } from '../../contexts/AppProvider';
import { TrashIcon } from '../../icons';
import { IFormField, IFormFieldOption } from '../../interfaces/IFormField';
import Can, { isPermitted } from '../Can';
import { RichTextEditor, TextInput } from '../FormControls';
import { firstCharacterCapitalize as capitalize } from '../../utils/helpers';
import { useProjectsContext } from '../../contexts/ProjectsProvider';
interface ITemplateFieldOptionsInput {
  options: IFormFieldOption[];
  name;
  label?: string;
  setValue: UseFormSetValue<IFormField>;
  type?: string;
}

const TemplateFieldOptionsInput = ({
  options,
  name,
  label,
  setValue,
  type
}: ITemplateFieldOptionsInput) => {
  const { user } = useAppContext();
  const { currentProjectTemplate } = useProjectsContext();
  const {
    control,
    watch,
    getValues,
    setValue: setOptionValue,
  } = useForm<{ options: { label: string; metadata: { isBold?: boolean } }[] }>({
    mode: 'all',
    defaultValues: {
      options: options.map((o) => ({ label: o.label, metadata: o.metadata })),
    },
  });
  const [fieldOptions, setFieldOptions] = useState<IFormFieldOption[]>(options);

  const isPermittedToEdit = isPermitted({
    user,
    action: 'projectTemplates.edit',
    data: { projectTemplate: currentProjectTemplate }
  });

  useEffect(() => {
    const subscription = watch((value) => {
      if (Array.isArray(value.options)) {
        const options: IFormFieldOption[] = value.options
          .map((o) => ({ label: o?.label || '', value: o?.label || '', metadata: o?.metadata || { isBold: false } }));
        setValue(name, options);
      }
    });

    return () => {
      subscription.unsubscribe();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    setFieldOptions(options);
  }, [options]);

  const nameSingular = pluralize(name, 1);

  const renderField = (option, type, index) => {
    if (type === "staticText") {
      return (
        <Box w="full">
          <TextInput
            label={`${capitalize(nameSingular)} # ${index + 1}`}
            control={control}
            name={`options.[${index}].label`}
            validations={{ notEmpty: true }}
            disabled={!isPermittedToEdit}
          />
          <VStack align="left" marginTop="2">
            <HStack>
              <Switch
                id={`metadata.${index}.isBold`}
                isChecked={option?.metadata?.isBold === true}
                disabled={!isPermittedToEdit}
                onChange={(e) =>
                  setOptionValue(
                    `options.${index}.metadata.isBold`,
                    e.target.checked ? true : false
                  )
                }
              />
              <FormLabel htmlFor={`metadata.${index}.isBold`}>
                Use Bold Font
              </FormLabel>
            </HStack>
          </VStack>
        </Box>
      );
    }

    if(type === "staticRichText") {
      return (
        <RichTextEditor
          control={control}
          styles={{ width: '100%' }}
          name={`options.[${index}].label`}
          validations={{ notEmpty: true }}
          disabled={!isPermittedToEdit}
          customConfig={{ minHeight: '100px' }}
        />
      )
    }

    return (
      <TextInput
        label={`${capitalize(nameSingular)} # ${index + 1}`}
        control={control}
        name={`options.[${index}].label`}
        validations={{ notEmpty: true }}
        disabled={!isPermittedToEdit}
      />
    );
  };

  return (
    <>
      <Text mt={2} fontSize="sm" fontWeight="bold" textTransform="capitalize">
        {label || name}
      </Text>
      {fieldOptions.map((option: IFormFieldOption, index: number) => (
        <Flex
          alignItems="center"
          key={`option-field-${fieldOptions.length}-${index}`}
        >
          {renderField(option, type, index)}
          <Can
            action="projectTemplates.edit"
            data={{ projectTemplate: currentProjectTemplate }}
            yes={() => (
              <TrashIcon
                mt="5"
                ml="4"
                cursor="pointer"
                onClick={() => {
                  let values = getValues().options;
                  let filtered = values.filter((_, i) => i !== index);
                  setOptionValue("options", filtered);
                  const newOptions = filtered.map((v) => ({
                    label: v.label,
                    value: v.label,
                    metadata: v.metadata
                  }));
                  setFieldOptions(() => {
                    setValue(name, newOptions);
                    return newOptions;
                  });
                }}
              />
            )}
          />
        </Flex>
      ))}
      <Can
        action="projectTemplates.edit"
        data={{ projectTemplate: currentProjectTemplate }}
        yes={() => (
          <Button
            mt="2"
            w="150px"
            h="28px"
            fontSize="smm"
            onClick={() => {
              let values = getValues().options;
              setOptionValue("options", [...values, { label: "", metadata: { isBold: false } }]);
              setFieldOptions(() => {
                const newVal = [
                  ...values.map((v) => ({ label: v.label, value: v.label, metadata: v.metadata })),
                  { label: "", value: "",  metadata: { isBold: false } },
                ];
                setValue(name, newVal);
                return newVal;
              });
            }}
          >
            Add {nameSingular}
          </Button>
        )}
      />
    </>
  );
};

export default TemplateFieldOptionsInput;
