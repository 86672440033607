import { Button, HStack, useDisclosure } from "@chakra-ui/react";
import { CheckIcon, CrossIcon } from "../../../icons";
import SignatureModal from "../SignatureModal";

const SignatureTableControl = ({ control, name, disabled, validations = {} }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tableName, index, columnName] = name.split('.');
  const value = control._formValues?.[tableName]?.[index]?.[columnName];

  return (
    <HStack>
      {value ? <CheckIcon color='breSuccess' w="16px" /> : <CrossIcon color='breFail' w="16px" />}
      {!disabled && (<>
        <Button
          bg="brePink"
          color="white"
          fontSize="sm"
          h="24px"
          onClick={onOpen}
        >{value ? 'Edit' : 'Sign'}</Button>
        <SignatureModal
          control={control}
          name={name}
          isOpen={isOpen}
          onClose={onClose}
          validations={validations}
        />
      </>)}
    </HStack>
  );
};

export default SignatureTableControl;
