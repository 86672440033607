import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { IFormField, IFormFieldOption } from "../../interfaces/IFormField";
import { Validations } from "../../interfaces/Validations";
import { RichTextEditor, TextInput } from "../FormControls";
import { Box, Flex, Switch, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

interface ITemplateFieldLabelInput {
  control: any;
  name;
  label?: string;
  setValue: UseFormSetValue<IFormField>;
  getValues: UseFormGetValues<IFormField>;
  type?: string;
  validations?: Validations;
  placeholder?: string;
  disabled?: boolean;
}

export const TemplateFieldLabelInput = ({
  control,
  name,
  label,
  placeholder,
  disabled,
  validations = {},
  setValue,
  getValues,
}: ITemplateFieldLabelInput) => {
  const richTextInputValue = getValues("useRichTextLabelInput");
  const [useRichTextInput, setRichtextInput] = useState(richTextInputValue ?? false);

  const stripHTML = (htmlString: string) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent || tempDiv.innerText || '';
  }

  const isHTMLString = (str) => {
    const htmlRegex = /<\/?[a-z][\s\S]*>/i; // Matches opening or closing tags
    return htmlRegex.test(str);
  }

  useEffect(() => {
    setValue("useRichTextLabelInput", useRichTextInput);
    if(!useRichTextInput){
      const value = getValues(name);
      isHTMLString(value) && setValue(name, stripHTML(value));
    }
  }, [useRichTextInput]);

  return (
    <Box>
      {useRichTextInput ? (
        <RichTextEditor
          control={control}
          styles={{ width: "100%" }}
          name={name}
          label={label}
          placeholder={placeholder}
          validations={validations}
          disabled={disabled}
        />
      ) : (
        <TextInput
          control={control}
          styles={{ width: "100%" }}
          name={name}
          label={label}
          placeholder={placeholder}
          validations={validations}
          disabled={disabled}
        />
      )}
      <Flex alignItems="center" mt="4">
        <Switch
          isChecked={useRichTextInput}
          onChange={() => {
            setRichtextInput(!useRichTextInput);
          }}
          disabled={disabled}
        />
        <Text ml="3">Use Rich Text</Text>
      </Flex>
    </Box>
  );
};
