import { createIcon } from "@chakra-ui/icons";

const PreTestIcon = createIcon({
  displayName: "PreTest",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.111 3.232L12.293 2.414L13.707 1L21.707 9L20.293 10.414L19.475 9.596L17.3623 11.7087C14.4387 11.7809 12.0739 14.1458 12.0017 17.0693L8.682 20.389C7.831 21.239 6.701 21.707 5.5 21.707C4.299 21.707 3.169 21.239 2.318 20.39C1.468 19.539 1 18.409 1 17.207C1 16.005 1.468 14.875 2.318 14.025L13.111 3.232ZM13.363 15.708L16.001 13.07C14.7787 13.5143 13.8072 14.4857 13.363 15.708ZM13.072 8.364L15.475 10.768L18.06 8.181L14.525 4.646L3.732 15.439C3.26 15.912 3 16.539 3 17.207C3 17.875 3.26 18.502 3.732 18.975C4.677 19.919 6.322 19.919 7.267 18.975L8.403 17.839L6 15.435L7.415 14.022L9.818 16.426L11.233 15.011L10.243 14.021L11.657 12.607L12.647 13.597L14.061 12.182L11.658 9.778L13.072 8.364Z"
        fill="currentColor"
      />
      <path
        d="M17.5 12.707C15.0187 12.707 13 14.7257 13 17.207C13 19.6883 15.0187 21.707 17.5 21.707C19.9813 21.707 22 19.6883 22 17.207C22 14.7257 19.9813 12.707 17.5 12.707ZM17.5 20.807C15.5151 20.807 13.9 19.192 13.9 17.207C13.9 15.2221 15.5151 13.607 17.5 13.607C19.4849 13.607 21.1 15.2221 21.1 17.207C21.1 19.192 19.4849 20.807 17.5 20.807Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6499 17.2071C12.6499 14.5325 14.8253 12.3571 17.4999 12.3571C20.1745 12.3571 22.3499 14.5325 22.3499 17.2071C22.3499 19.8817 20.1745 22.0571 17.4999 22.0571C14.8253 22.0571 12.6499 19.8817 12.6499 17.2071ZM14.2499 17.2071C14.2499 18.9987 15.7083 20.4571 17.4999 20.4571C19.2916 20.4571 20.7499 18.9987 20.7499 17.2071C20.7499 15.4154 19.2916 13.9571 17.4999 13.9571C15.7083 13.9571 14.2499 15.4154 14.2499 17.2071ZM12.9999 17.2071C12.9999 14.7258 15.0186 12.7071 17.4999 12.7071C19.9812 12.7071 21.9999 14.7258 21.9999 17.2071C21.9999 19.6884 19.9812 21.7071 17.4999 21.7071C15.0186 21.7071 12.9999 19.6884 12.9999 17.2071ZM13.8999 17.2071C13.8999 19.192 15.515 20.8071 17.4999 20.8071C19.4849 20.8071 21.0999 19.192 21.0999 17.2071C21.0999 15.2221 19.4849 13.6071 17.4999 13.6071C15.515 13.6071 13.8999 15.2221 13.8999 17.2071Z"
        fill="currentColor"
      />
      <path
        d="M17.8498 15.707H17V17.5724L18.3992 18.707L19 18.2198L17.8498 17.2871V15.707Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.1495 15.4071V17.1442L19.476 18.2199L18.3989 19.0933L16.6997 17.7155V15.4071H18.1495ZM17.8495 17.2872V15.7071H16.9997V17.5725L18.3989 18.7071L18.9997 18.2199L17.8495 17.2872Z"
        fill="currentColor"
      />
    </>
  ),
});

export default PreTestIcon;
