import { SetStateAction, Dispatch, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Box, Button, Flex, Spacer, Spinner, Tooltip } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import SignatureCanvas from "react-signature-canvas";
import axios from "axios";
import { get } from "lodash";

import { IField } from "../../interfaces/IField";
import { DefinedValidations } from "../../interfaces/Validations";
import { Asterisk } from "../../icons";
import useIsIpad from "../../hooks/useIsIpad";
import useValidate from "../../hooks/useValidate";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { useAppContext } from "../../contexts/AppProvider";

interface ISignatureApproval extends IField {
  placeholder?: string;
  variant?: string;
  setIsSignAndCompleteDisabled?: Dispatch<SetStateAction<boolean>>;
  styles?: {
    font?: string;
    fullWidth?: boolean;
  };
}

const definedValidations: DefinedValidations = {
  notEmpty: (label, validationValue, value) => {
    if (validationValue && !value) {
      return `Cannot be empty`;
    }
  },
};

const SignatureApproval = ({
  control,
  name,
  label,
  tooltip = "",
  validations = {},
  disabled,
  styles,
  clearAfterEdit,
  useRichTextLabelInput,
  setIsSignAndCompleteDisabled,
}: ISignatureApproval) => {
  const uploadHandler = useRef<any>();
  const validate = useValidate(
    label || name,
    validations || {},
    definedValidations
  );
  const firstRender = useRef(true);
  const [sigPad, setSigPad] = useState<any>({});
  const clearRef = useRef<HTMLButtonElement>(null);
  const [uploading, setUploading] = useState<Boolean>(false);
  const [signatureLoaded, setSignatureLoaded] = useState<Boolean>(false);
  const ipad = useIsIpad();
  const { settings } = useAppContext();

  useEffect(() => {
    const value = get(control._formValues, name);
    if (
      value &&
      value !== "uploading" &&
      !signatureLoaded &&
      sigPad?.fromDataURL &&
      !uploading
    ) {
      setUploading(true);
      loadSavedSignature(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    control._formValues,
    sigPad?.fromDataURL,
    signatureLoaded,
    uploading,
    settings,
  ]);

  useEffect(() => {
    if (sigPad?.off && sigPad?.on && sigPad?.clear) {
      if (disabled) {
        sigPad?.off();
      } else {
        sigPad?.on();
        if (!firstRender.current) {
          if (clearAfterEdit) clearRef?.current?.click();
        }
      }
      firstRender.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, sigPad?.off, sigPad?.on]);

  const uploadFilesForField = async (file: File, sigId: string) => {
    if (setIsSignAndCompleteDisabled) setIsSignAndCompleteDisabled(true);
    const reportFilesData = new FormData();
    reportFilesData.append("fileLocation", `_formData/${sigId}`);
    reportFilesData.append("spSiteUrl", settings?.spSiteUrl || "");
    reportFilesData.append(
      "spDocumentLibrary",
      settings?.spDocumentLibrary || ""
    );
    reportFilesData.append("file", file);
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/files/saveSignature`,
      reportFilesData,
      { withCredentials: true }
    );
    if (setIsSignAndCompleteDisabled) setIsSignAndCompleteDisabled(false);
    return res.data;
  };

  const loadSavedSignature = async (value: string) => {
    const { data: savedSignature } = await axios.get(
      `${process.env.REACT_APP_API_URL}/files/getFile`,
      {
        params: {
          fileLocation: `_formData/${value}.png`,
          spSiteUrl: settings?.spSiteUrl || "",
          spDocumentLibrary: settings?.spDocumentLibrary || "",
        },
        withCredentials: true,
      }
    );

    const toDataURL = (url) =>
      fetch(url)
        .then((response) => response.blob())
        .then(
          (blob) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            })
        );

    const base64Data = await toDataURL(savedSignature);
    sigPad.fromDataURL(base64Data);
    setSignatureLoaded(true);
    setUploading(false);
  };

  const renderLoader = () => (
    <Flex w="full" h="full" align="center" justify="center">
      <Spinner size="xl" thickness="4px" />
    </Flex>
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate }}
      render={({ field, fieldState }) => {
        const { onChange } = field;
        const { error } = fieldState;

        const _handleUploader = () => {
          clearTimeout(uploadHandler.current);
          uploadHandler.current = setTimeout(() => {
            setSignatureLoaded(true);
            onChange({ target: { name, value: "uploading" } });
            const sigId = uuidv4();
            uploadFilesForField(
              sigPad.getTrimmedCanvas().toDataURL("image/png"),
              sigId
            );
            onChange({ target: { name, value: sigId } });
          }, 500);
        };

        return (
          <Box w="full" id={name} mt="none" mb="2">
            {label && (
              <>
                {useRichTextLabelInput ? (
                  <Flex
                    align="center"
                    color={
                      error
                        ? "signatureApproval.labelFont.error"
                        : styles
                        ? styles?.font
                        : "signatureApproval.labelFont.normal"
                    }
                    fontWeight="bold"
                    fontSize="ssm"
                    position="static"
                    left="none"
                    zIndex={1}
                    minH="16px"
                    wrap="wrap"
                  >
                    <Box
                      as="span"
                      display="inline"
                      sx={{
                        "& p:first-of-type": {
                          display: "inline",
                          margin: 0,
                        },
                      }}
                      dangerouslySetInnerHTML={{ __html: label }}
                    />
                    {!disabled && validations?.notEmpty && (
                      <Box
                        as="span"
                        ml="1"
                        display="inline"
                        alignItems="center"
                      >
                        <Asterisk
                          h="8px"
                          ml="5px"
                          mb="8px"
                          fill="textInput.iconAsterisk"
                          stroke="textInput.iconAsterisk"
                        />
                      </Box>
                    )}
                    {tooltip && (
                      <Box
                        as="span"
                        ml="1"
                        display="inline"
                        alignItems="center"
                      >
                        <Tooltip hasArrow label={tooltip} placement="top">
                          <InfoOutlineIcon h="14px" />
                        </Tooltip>
                      </Box>
                    )}
                  </Flex>
                ) : (
                  <Flex
                    pt={2}
                    pb={1}
                    align="center"
                    justify="space-between"
                    mb="none"
                  >
                    <Box
                      color={
                        error
                          ? "signatureApproval.labelFont.error"
                          : styles
                          ? styles?.font
                          : "signatureApproval.labelFont.normal"
                      }
                      fontWeight="bold"
                      fontSize="11px"
                      position="static"
                      left="none"
                      zIndex={2}
                      minH="16px"
                    >
                      {label}
                      {!disabled && validations?.notEmpty && (
                        <Asterisk
                          h="8px"
                          ml="5px"
                          mb="8px"
                          fill="signatureApproval.iconAsterisk"
                          stroke="signatureApproval.iconAsterisk"
                        />
                      )}{" "}
                      {tooltip && (
                        <Tooltip hasArrow label={tooltip} placement="top">
                          <InfoOutlineIcon mb={1} h="14px" />
                        </Tooltip>
                      )}
                    </Box>
                  </Flex>
                )}
              </>
            )}

            <Flex
              maxW={styles?.fullWidth ? "" : "345px"}
              h="115px"
              p="5px"
              bg="signatureApproval.signature.bg"
              borderRadius="10px"
              borderColor="signatureApproval.signature.borderColor"
              borderWidth="1px"
              alignContent="center"
            >
              <Spacer />
              {uploading && renderLoader()}
              <Box w="100%" h="100%" display={uploading ? "none" : "block"}>
                <SignatureCanvas
                  ref={setSigPad}
                  onEnd={_handleUploader}
                  backgroundColor="#DAE0E8"
                  penColor="black"
                  canvasProps={{ className: "sigPad" }}
                  clearOnResize={false}
                />
              </Box>
              <Spacer />
            </Flex>
            {!uploading && !disabled ? (
              <Button
                maxW={styles?.fullWidth ? "" : "345px"}
                h="22px"
                w="full"
                fontSize="13px"
                borderWidth={1}
                bg="white"
                mt={1}
                borderColor="signatureApproval.signature.borderColor"
                ref={clearRef}
                onClick={() => {
                  setTimeout(() => {
                    // had to add a timeout otherwise it would clear the canvas but not clear form value
                    // when changing form back from completed to edit
                    onChange({ target: { name, value: "" } });
                    sigPad.clear();
                  }, 1);
                }}
                onTouchEnd={() => {
                  if (ipad) {
                    onChange({ target: { name, value: "" } });
                    sigPad.clear();
                  }
                }}
                _hover={{ bg: "signatureApproval.error", color: "white" }}
              >
                Clear
              </Button>
            ) : (
              <Box h="22px" w="full" mt={1}></Box>
            )}
            {error && (
              <Box fontSize="ssm" ml={1} color="signatureApproval.error">
                {error.message}
              </Box>
            )}
          </Box>
        );
      }}
    />
  );
};
export default SignatureApproval;

export const signatureApprovalStyles = {
  signatureApproval: {
    font: "#777777",
    bg: "#FFFFFF",
    labelFont: {
      normal: "#131535",
      error: "#E53E3E",
    },
    border: {
      normal: "rgba(129, 129, 151, 0.4)",
      error: "#E53E3E",
      focus: {
        normal: "#777777",
        error: "#E53E3E",
      },
    },
    activeBg: "#FFFFFF",
    disabled: {
      font: "#2B3236",
      border: "#EEEEEE",
      bg: "#f7f7f7",
    },
    placeholder: "#CBCCCD",
    error: "#E53E3E",
    iconAsterisk: "#DC0043",
    signature: {
      bg: "#DAE0E8",
      borderColor: "rgba(129, 129, 151, 0.4)",
      backgroundColor: "#DAE0E8",
    },
  },
};
