import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from "@chakra-ui/react";

import { SignatureApproval } from ".";

const SignatureModal = ({ control, name, isOpen, onClose, validations = {} }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent rounded="20px" w="330px" pb="20px">
        <ModalHeader color="markAsCompleteModal.headerColor" fontSize="smm">Signature</ModalHeader>
        <ModalCloseButton onTouchEnd={onClose} />
        <ModalBody>
          <SignatureApproval control={control} name={name} label="Signature" validations={validations} />
          <Button
            w="full"
            mt="15px"
            bg='brePink'
            color='markAsCompleteModal.buttonColor'
            pl='5px'
            pr='15px'
            fontSize='smm'
            borderRadius='10px'
            _hover={{ bg: 'reportFormHeader.buttonDisableBg', color: 'reportFormHeader.buttonDisableColor' }}
            onClick={onClose}
            onTouchEnd={onClose}
          >
            Close
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );

}

export default SignatureModal;

export const signatureModalStyles = {
  signatureModal: {
    headerColor: "#313233",
    buttonColor: "#ffffff"
  },
};
